<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.rescuerQualificationManagement')"
    id="rescuerQualificationManagement"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form :columns="basicColumns" :option="btnOption"></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.maintenanceLevelInfo") }}</span>
      </template>
      <div class="text item">
        <jl-table
          @init="init"
          @handleDelete="handleDelete"
          @handleEdit="handleEdit"
          :tableData="brandData"
          :options="brandOptions"
          :columns="brandColumns"
          :operates="brandOperates"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "Qualificationcou",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 10,
      },
      basicColumns: [
        {
          prop: "userName",
          label: "staffAccount",
          readonly: true,
        },
        {
          prop: "nickName",
          label: "staffName",
          readonly: true,
        },
        {
          prop: "telephone",
          label: "mobilePhone",
          readonly: true,
        },
        {
          prop: "picture3",
          label: "idCard",
          type: "upload",
          readonly: true,
        },
        {
          prop: "picture1",
          label: "certificatePhoto",
          type: "upload",
          readonly: true,
        },
        {
          prop: "picture2",
          label: "certificatePhoto",
          type: "upload",
          readonly: true,
        },
      ],
      btnOption: [
        {
          label: "approved",
          class: "primary",
          show: false,
          method: (row) => {
            setStatus(row.id, 2);
          },
        },
        {
          label: "notApproved",
          class: "cancel",
          show: false,
          method: (row) => {
            setStatus(row.id, 3);
          },
        },
      ],
      brandData: [],
      brandOptions: {
        isAdd: proxy.$defined.btnPermission("编辑审核救援人员信息"),
        isEdit: proxy.$defined.btnPermission("编辑审核救援人员信息"),
        isDelete: proxy.$defined.btnPermission("编辑审核救援人员信息"),
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      brandColumns: [
        {
          prop: "brandName",
          label: "elevatorBrand",
          align: "center",
          type: "select",
          props: { label: "name", value: "index" },
          data: [],
          change: (val) => {
            state.allBrand[val].level.map((item) => {
              item.name = t("i18n." + item.name);
            });
            state.brandColumns[1].data = state.allBrand[val].level;
          },
        },
        {
          prop: "levelName",
          label: "maintenanceLevel",
          align: "center",
          formatter: (row) => {
            return t("i18n." + row.levelName);
          },
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
        },
      ],
      brandOperates: {
        width: 140,
        fixed: "right",
        list: [],
      },
      detail: "",
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.getRescuerOne(id);
      if (
        data.isAudit == 1 &&
        proxy.$defined.btnPermission("编辑审核救援人员信息")
      ) {
        state.btnOption[0].show = true;
        state.btnOption[1].show = true;
      }
      state.detail = data;
      state.brandData = data.levelList;
      setIsEdit(true, data);
    };

    const getBrandList = async () => {
      var index = state.brandColumns.findIndex((item) => {
        return item.prop === "brandName";
      });
      const { data } = await proxy.$api.system.getBrandLevel();
      state.allBrand = data;
      let arr = [];
      data.map((item, i) => {
        item.brand.index = i;
        arr.push(item.brand);
      });
      state.brandColumns[index].data = arr;
    };
    getBrandList();

    const setStatus = async (id, status) => {
      await proxy.$api.system.setRescueStatus(id, status);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      router.go(-1);
    };

    const setIsEdit = (flag, data) => {
      state.basicColumns.map((item, index) => {
        item.readonly = flag;
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          } else {
            item.value = data[item.prop];
            state.basicColumns[index].value = data[item.prop];
          }
        }
      });
    };

    const handleEdit = async (val) => {
      // 新增/编辑数据
      let id = route.query.id;
      await proxy.$api.system.addEditRescuer(id, { levelId: val.levelName });
      proxy.$defined.tip(t("i18n.success"), "success");
      // 初始化 刷新
      init();
    };

    const handleDelete = (row) => {
      let id = route.query.id;
      proxy.$api.system.delRescuer(id, { levelId: row.levelId }).then(() => {
        init();
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    return {
      ...toRefs(state),
      init,
      setIsEdit,
      handleDelete,
      setStatus,
      handleEdit,
    };
  },
};
</script>

<style lang="scss">
#rescuerQualificationManagement {
  .form-button,
  .form-button .el-form-item__content {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
</style>
